export enum Icons {
  Bed = 'bed',
  Calender = 'calender',
  Cancel = 'cancel',
  ChangeDescription = 'changeDescription',
  ChangeLocation = 'changeLocation',
  Chat = 'chat',
  Check = 'check',
  Clock = 'clock',
  Diagnosis = 'diagnosis',
  Escalation = 'escalation',
  Inserted = 'inserted',
  Oneway = 'oneway',
  Reordered = 'reordered',
  Transport = 'transport',
  EventBusy = 'eventBusy',
  Lock = 'lock',
  Report = 'report',
  EventAvailable = 'eventAvailable',
  EventUpcoming = 'eventUpcoming',
  PatientPositioning = 'patientPositioning',
  Team = 'team',
  Barcode = 'barcode',
  CheckList = 'checklist',
  Spi = 'spi',
  ORLocation = 'orlocation',
  Location = 'Location',
  Description = 'description',
  Anesthesia = 'anesthesia',
  Insurance = 'insurance',
  Male = 'male',
  Female = 'female',
  Unisex = 'unisex',
  Work = 'work',
  Case = 'case',
  PremedicationChecked = 'premedicationChecked',
  PremedicationMissing = 'premedicationMissing',
  PremedicationNotApproved = 'premedicationNotApproved',
  Download = 'download',
  Visibility = 'visibility',
  VisibilityOff = 'visibilityOff',
  BackArrow = 'backArrow',
  ForwardArrow = 'forwardArrow',
  LocalAnesthesia = 'localAnesthesia',
  Search = 'search',
  Close = 'close',
  TransportDone = 'transportDone',
  Cave = 'cave',
  Tv = 'tv',
  StartRight = 'startRight',
  Patient = 'patient',
  Smile = 'smile',
  AVTimer = 'avTimer',
  Bedroom = 'bedroom',
  DisplaySettings = 'displaySettings',
}
