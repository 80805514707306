import { Bytes, LogicalTimestamp, Value, ValueObject } from '@sqior/js/data';
import { IdOperation } from './id-operation';
import { OperationState, OperationStateJSON, OperationType } from './operation';

/** Common content types */
export enum ContentTypes {
  JSON = 'application/json', // Internal representation: JS object
  JPEG = 'image/jpeg', // Internal representation: Binary object
}

export type ContentMetaData = {
  contentType: string;
  filename?: string;
};

export type ReadResult = [
  Value, // Data
  string | ContentMetaData, // Content Type
  LogicalTimestamp // Timestamp
];

export class ReadOperation extends IdOperation {
  constructor(id: string, value?: Value) {
    super(OperationType.Read, id);
    if (value !== undefined) this.requestData = value;
  }

  async result(): Promise<[Value, ContentMetaData | undefined]> {
    await this.completion();
    if (this.data !== undefined) return [this.data, this.contentMetaData];
    else if (this.error !== undefined && this.error !== null) throw this.error;
    else throw Error('Result data is not set');
  }

  completeRead(
    data: Value,
    contentMetaData: string | ContentMetaData,
    timestamp: LogicalTimestamp
  ) {
    this.data = data;
    if (typeof contentMetaData === 'string')
      this.contentMetaData = { contentType: contentMetaData };
    else this.contentMetaData = contentMetaData;
    this.complete(timestamp);
  }

  override resultToJSON(): OperationStateJSON {
    const res = super.resultToJSON();
    if (this.data !== undefined) res['data'] = this.data;
    if (this.contentMetaData?.contentType) res['type'] = this.contentMetaData?.contentType;
    if (this.contentMetaData?.filename) res['filename'] = this.contentMetaData?.filename;
    return res;
  }

  override completeFromJSON(msg: OperationStateJSON) {
    if (msg.state == OperationState.Completed) {
      this.data = msg['data'] as Value;
      const contentType = msg['type'] as string;
      const filename = msg['filename'] as string;
      if (contentType && filename)
        this.contentMetaData = { contentType: contentType, filename: filename };
      else if (contentType) this.contentMetaData = { contentType: contentType };
    }
    super.completeFromJSON(msg);
  }

  override toJSON() {
    const obj: ValueObject = { id: this.id };
    if (this.requestData) obj['data'] = this.requestData;
    return obj;
  }

  static fromJSON(obj: Value) {
    if (typeof obj === 'object' && !(obj instanceof Bytes) && !(obj instanceof Array)) {
      const id = obj['id'];
      if (typeof id === 'string') return new ReadOperation(id, obj['data']);
    } else if (typeof obj === 'string') return new ReadOperation(obj);
    throw new Error(
      'Data provided to ReadOperation.fromJSON does not match expected format of object with ID string'
    );
  }

  requestData?: Value;
  data?: Value;
  contentMetaData?: ContentMetaData;
}
