import { Bytes, ClockTimestamp } from '@sqior/js/data';
import { OperationSpec, OperationType } from '@sqior/js/operation';
import { UID } from '@sqior/js/uid';
import { joinPath } from '@sqior/js/url';

export const MediaPath = 'media';
export const MediaUploadSubPath = 'upload';
export const MediaUploadPath = joinPath(MediaPath, MediaUploadSubPath);
export const MediaDownloadSubPath = 'download';
export const MediaDownloadPath = joinPath(MediaPath, MediaDownloadSubPath);
export const MediaDeleteSubPath = 'delete';
export const MediaDeletePath = joinPath(MediaPath, MediaDeleteSubPath);

/* Specification of the media upload operation */
export type MediaInfo = { timestamp: ClockTimestamp; type: string; filename?: string; data: Bytes };
export function UploadMedia(
  path: string,
  timestamp: number,
  type: string,
  binaryData: Bytes,
  filename?: string
): OperationSpec<MediaInfo> {
  const operationData: MediaInfo = {
    timestamp: timestamp,
    type: type,
    data: binaryData,
  };
  if (filename) operationData.filename = filename;
  return {
    type: OperationType.Add,
    path: joinPath(path, MediaUploadSubPath),
    data: operationData,
  };
}
export function DeleteMedia(id: UID): OperationSpec<UID> {
  return { type: OperationType.Delete, path: MediaDeletePath, data: id };
}

/** Loads media */
export function LoadMedia(id: string): OperationSpec<string> {
  return { type: OperationType.Read, path: MediaDownloadPath, data: id };
}
