import { Value } from '@sqior/js/data';
import { Operation, OperationType } from './operation';
import { TemporaryValue } from './temporary-value';

export class IdOperation extends Operation {
  constructor(type: OperationType, id: string) {
    /* The ID may be a temporary value, so observe it */
    const temp = new TemporaryValue(id);
    super(type, temp.final);
    this.temp = temp;
    if (!temp.final)
      this.temp.change.on((value) => {
        /* Inform pot. users about the change */
        if (value) this.contentChanged(true);
        /* Set this to failed if the temporary was cancelled */ else if (!value) this.fail();
      });
  }

  get id() {
    return this.temp.current;
  }

  override toJSON(): Value {
    return this.id;
  }

  private temp: TemporaryValue;
}
