import { StdTimer, TimerInterface } from '@sqior/js/data';
import { CacheAccess } from './cache-access';
import { Closable } from '@sqior/js/async';
import { StopListening } from '@sqior/js/event';

/** Manages the clean-up of cached items after a certain retention period after the last cache access.
 *  As the retention time does not to be managed with millisecond precision, the time is partioned into
 *  generations and the cached items are managed according to their monotonically increasing generation index.
 */
export class CacheCleanup implements Closable {
  static readonly Generations = 100;

  constructor(retention: number, timer: TimerInterface = new StdTimer()) {
    /* Start a periodic timer incrementing the generation and cleaning up all items assigned to the current generation */
    this.stopTimer = timer.periodic(() => {
      this.tick();
    }, retention / CacheCleanup.Generations);
  }

  /** Deactivates by stopping the periodic timer */
  async close() {
    this.stopTimer();
  }

  /** Enlists an item for a certain generation */
  private enlist(access: CacheAccess, gen: number) {
    const rel = gen - this.currentGeneration;
    if (rel < 0 || rel > CacheCleanup.Generations)
      throw new Error('Illegal generation provided to CacheCleanup.enlist(): ' + gen);
    while (this.access.length <= rel) this.access.push(new Set<CacheAccess>());
    this.access[rel].add(access);
  }

  add(access: CacheAccess) {
    /* Add the item to the last current generation */
    const gen = this.generation + CacheCleanup.Generations;
    this.enlist(access, gen);
    return gen;
  }

  remove(access: CacheAccess) {
    const gen = access.enlistGeneration - this.generation;
    const accesses = this.access[gen];
    if (accesses) accesses.delete(access);
  }

  /** Notifies all items that are enlisted in the current generation and which have not been accessed in the meantime */
  private tick() {
    /* Extract the current generation and increment */
    const currentAccesses = this.access.shift();
    this.generation++;
    if (!currentAccesses) return;
    /* Iterate and either enlist again if it was accessed in the meantime or trigger cache clean-up */
    for (const ca of currentAccesses)
      if (ca.last + CacheCleanup.Generations > ca.enlistGeneration)
        this.enlist(ca, (ca.enlistGeneration = ca.last + CacheCleanup.Generations));
      else ca.emit();
  }

  get currentGeneration() {
    return this.generation;
  }

  get size() {
    let count = 0;
    for (const a of this.access) count += a.size;
    return count;
  }

  private readonly stopTimer: StopListening;
  private generation = 0;
  private readonly access: Set<CacheAccess>[] = [];
}
