import { v4 as uuid } from 'uuid';

/* Basic UID type */

export type UID = string;

/* Function creating a UID */

export function createUID(): UID {
  /* Prepending the current timestamp to a UUID to make UID even more unique */
  return Date.now().toString(16) + '-' + uuid();
}
