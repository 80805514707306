/** Enum specifying abstract colors */

// similar to: https://learn.microsoft.com/en-us/dotnet/api/system.windows.media.colors?view=windowsdesktop-8.0

export enum ColorCodes {
  SqiorBlue = 'sqior-blue',
  GreenOlive = 'green-olive',
  Purple = 'purple',
  Red = 'red',
  Blue = 'blue',
  Peach = 'peach',
  SteelBlue = 'steel-blue',
  Cream = 'cream',
  Cyan = 'cyan',
  RiverBlue = 'river-blue',
  Green = 'green',
  LightGreen = 'light-green',
  Yellow = 'yellow',
  Sand = 'sand',
  Orange = 'orange',
  Salmon = 'salmon',
  Violet = 'violet',
  Pink = 'pink',
  LightPink = 'light-pink',
  LightBrown = 'light-brown',
  EggShell = 'eggshell',
  Brown = 'brown',
  LightYellow = 'light-yellow',
  DarkBlue = 'dark-blue',
  LightOrange = 'light-orange',
  DarkGray = 'dark-gray',
  Gray = 'gray',
  White = 'white',
  Teal = 'teal',

  // Color codes for a green-red-scale with 4 level, level 1=green, level 4 = red
  // ScaleRG4Level1 = 'scale-rg4-level1',
  // ScaleRG4Level2 = 'scale-rg4-level2',
  // ScaleRG4Level3 = 'scale-rg4-level3',
  // ScaleRG4Level4 = 'scale-rg4-level4',

  ScaleRG3Level1 = 'scale-rg3-level1',
  ScaleRG3Level2 = 'scale-rg3-level2',
  ScaleRG3Level3 = 'scale-rg3-level3',
}
