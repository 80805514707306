import { StateContext } from '../state-context';
import { useContext, useEffect, useRef, useState } from 'react';
import { useStateOnce } from '@sqior/react/hooks';
import { Value, isEqual } from '@sqior/js/data';
import { State } from '@sqior/js/state';
import { StopListening } from '@sqior/js/event';

/** Helper hook function registering the use of a state */
function useSubState(state: State) {
  /* Increment and release the use count on the sub-state */
  useEffect(() => {
    /* Increase use count */
    state.use();
    /* Return a clean-up method that drops the use */
    return () => {
      state.dropUse();
    };
  }, [state]);
}

/** Hook type function that provides the state value and keeps it up-to-date */
export function useDynamicStateRaw<Type extends Value>(path: string) {
  /* Get the context state object - usually this is defined top-level */
  const stateContext = useContext(StateContext);

  /* Get Initial value from state */
  const stateObj = stateContext.subState(path);
  const lastState = stateObj.getRaw<Type>();
  /* Initialize the state variable which is actually manipulated via the provided setter function in the useEffect() below */
  const [value, setValue] = useStateOnce<Type | undefined>(() => lastState);

  /* Use an effect hook with clean-up to listen and stop listening to state changes */
  useEffect(() => {
    /* Register a listener and update value on change */
    const stopListening = stateObj.onTyped<Type>((val) => {
      setValue(val);
    });

    // Since useEffect() is called asynchronously, re-get the value and set to state if different
    const newValue = stateObj.getRaw<Type>();
    if (!isEqual(value, newValue)) setValue(newValue);

    /* Return a clean-up method that removes the listener */
    return () => {
      stopListening();
    };
    /* eslint-disable-next-line */
  }, [setValue, stateObj]);

  /* Register the use of the sub-state */
  useSubState(stateObj);

  return value;
}

/** Helper function to use the substate value */
function useSubstateValue<Type extends Value>(stateObj: State, defValue: Type) {
  const lastState = stateObj.get<Type>(defValue);
  /* Initialize the state variable which is actually manipulated via the provided setter function in the useEffect() below */
  const [value, setValue] = useStateOnce<Type>(() => lastState);

  /* Use an effect hook with *clean-up* to listen and stop listening to state changes */
  useEffect(() => {
    /* Register a listener and update value on change */
    const stopListening = stateObj.onTyped<Type>(() => {
      setValue(stateObj.get<Type>(defValue));
    });

    // Since useEffect() is called asynchronously, re-get the value and set to state if different
    const newValue = stateObj.get<Type>(defValue);
    if (!isEqual(value, newValue)) setValue(newValue);

    /* Return a clean-up method that removes the listener */
    return () => {
      stopListening();
    };
    /* eslint-disable-next-line */
  }, [defValue, stateObj, setValue]);

  return value;
}

/** Hook type function that provides the state value and keeps it up-to-date */
export function useDynamicStateIfAvailable<Type extends Value>(path: string, defValue: Type) {
  /* Get the context state object - usually this is defined top-level */
  const stateContext = useContext(StateContext);
  /* Get state object */
  const stateObj = stateContext.subState(path);
  /* Use it */
  return useSubstateValue(stateObj, defValue);
}

/** Hook type function that provides the state value and keeps it up-to-date */
export function useDynamicState<Type extends Value>(path: string, defValue: Type) {
  /* Get the context state object - usually this is defined top-level */
  const stateContext = useContext(StateContext);
  /* Get state object */
  const stateObj = stateContext.subState(path);
  /* Use it */
  const value = useSubstateValue(stateObj, defValue);

  /* Register the use of the sub-state */
  useSubState(stateObj);

  return value;
}
export default useDynamicState;

/*
# Multi-path dynmic state hook
*/

type StateObjs = {
  paths: string[];
  objs: { [path: string]: { stateObj: State; stop?: StopListening } };
};

/* Helper function update the state values */

function updateStateValues<Type extends Value>(
  stateObjs: StateObjs,
  setValues: React.Dispatch<React.SetStateAction<{ path: string; value?: Type }[]>>
) {
  /* Get the current state values */
  const values = stateObjs.paths.map((path) => {
    const res: { path: string; value?: Type } = { path };
    const stateObj = stateObjs.objs[path]?.stateObj;
    if (stateObj) {
      const value = stateObj.getRaw<Type>();
      if (value !== undefined) res.value = value;
    }
    return res;
  });

  /* Update the state values */
  setValues(values);
}

/** Hook type function that provides a list of state values and keeps it up-to-date */
export function useDynamicStates<Type extends Value>(paths: string[]) {
  /* Get the context state object - usually this is defined top-level */
  const stateContext = useContext(StateContext);

  /* Define the value returned - this is an array of paths with their respective values */
  const [values, setValues] = useState<{ path: string; value?: Type }[]>([]);

  /* Define a reference to a helper object that keeps track of the state objects */
  const stateObjs = useRef<StateObjs>({ paths: [], objs: {} });

  /* Use an effect hook to update the state objects and listeners upon a change of paths */
  useEffect(() => {
    /* Do a quick check if the paths are the same */
    const currentStateObjs = stateObjs.current;
    if (isEqual(paths, currentStateObjs.paths)) return;
    /* Variable for figuring out if something changed */
    let added = 0;
    /* Loop through all paths and check if a new one is included */
    for (const path of paths) {
      /* If the path is not in the state objects, add it */
      const entry = currentStateObjs.objs[path];
      if (entry) {
        if (entry.stop) continue;
        /* Re-initiate the listener */
        entry.stop = entry.stateObj.on(() => updateStateValues(currentStateObjs, setValues));
        /* Notify about use */
        entry.stateObj.use();
      } else {
        const stateObj = stateContext.subState(path);
        /* Notify about use */
        stateObj.use();
        /* Remember */
        currentStateObjs.objs[path] = {
          stateObj,
          stop: stateObj.on((val) => {
            updateStateValues(currentStateObjs, setValues);
          }),
        };
        added++;
      }
    }
    /* Check if there is a path that is no longer used */
    if (paths.length !== currentStateObjs.paths.length + added) {
      /* Create dictionary of desired paths */
      const desiredPaths = new Set<string>(paths);
      for (const path in currentStateObjs.objs)
        if (!desiredPaths.has(path)) {
          const entry = currentStateObjs.objs[path];
          if (entry.stop) {
            /* Stop the listener for changes */
            entry.stop();
            /* Indicate that this state is no longer used */
            entry.stateObj.dropUse();
          }
          /* Remove from the list */
          delete currentStateObjs.objs[path];
        }
    }
    /* Remember the path order (copy to not be affected by external changes to the array) */
    currentStateObjs.paths = [...paths];
    /* Update to set values */
    updateStateValues(currentStateObjs, setValues);
  }, [paths, stateObjs, stateContext]);

  /* Define another effect hook that makes sure to clean up the listeners at the end */
  useEffect(() => {
    const currentStateObjs = stateObjs.current;
    /* Re-initiate the listeners, if applicable */
    for (const path in currentStateObjs.objs) {
      const entry = currentStateObjs.objs[path];
      if (!entry.stop) {
        /* Re-initiate the listener */
        entry.stop = entry.stateObj.on(() => updateStateValues(currentStateObjs, setValues));
        /* Notify about use */
        entry.stateObj.use();
      }
    }
    return () => {
      /* Loop through all paths and remove the listeners */
      for (const path in currentStateObjs.objs) {
        const entry = currentStateObjs.objs[path];
        if (entry.stop) {
          /* Stop the listener for changes */
          entry.stop();
          delete entry.stop;
          /* Indicate that this state is no longer used */
          entry.stateObj.dropUse();
        }
      }
    };
  });

  return values;
}
