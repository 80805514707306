import { BiChannel, Message } from '@sqior/js/message';
import {
  AuthErrorCode,
  AuthErrorMessage,
  AuthRequestMessage,
  ConnectionMessageType,
  HandshakeInitMessage,
  HandshakeResponseCode,
  HandshakeResponseMessage,
  PingMessage,
} from './session-message';
import { Logger } from '@sqior/js/log';
import { IAuthServices, TokenHandler, TokenValidResult, UserInfo } from '@sqior/js/authbase';
import { ClockTimestamp, StdTimer, TimerHolder, TimerInterface } from '@sqior/js/data';
import { SessionHandler } from './session-handler';
import { Emitter } from '@sqior/js/event';

export type SessionControlError = HandshakeResponseCode | AuthErrorCode;

export class SessionControl {
  constructor(
    channel: BiChannel,
    sessionHandler: SessionHandler,
    version?: string,
    authServices?: IAuthServices,
    timer = new StdTimer()
  ) {
    this.channel = channel;
    this.sessionHandler = sessionHandler;
    if (version) this.version = version;
    if (authServices) this.authServices = authServices;
    this.abortController = new AbortController();
    this.timer = timer;
  }

  /** Shuts down the session control upon an external close request */
  close() {
    this.abortController.abort();
    this.tokenHandler?.close();
    delete this.tokenHandler;
    this.sessionTimeout.reset();
  }

  /** Handles session related messages */
  handle(msg: Message) {
    if (msg.type === ConnectionMessageType.HandshakeInit)
      this.handleHandshake(msg as HandshakeInitMessage, this.abortController.signal);
    else if (msg.type === ConnectionMessageType.AuthRequest)
      this.handleAuthRequest(msg as AuthRequestMessage);
    else if (msg.type === ConnectionMessageType.PingRequest)
      this.handlePingRequest(msg as PingMessage);
  }

  private sendHandshakeError(
    responseCode = HandshakeResponseCode.UnknownError,
    authError?: AuthErrorCode
  ) {
    /* Send error */
    const rsp: HandshakeResponseMessage = {
      type: ConnectionMessageType.HandshakeResponse,
      response: responseCode,
    };
    if (authError) rsp.error = authError;
    this.send.emit(rsp);
    /* Set state and close */
    this.disconnect.emit(authError ?? responseCode);
  }

  private sendAuthError(error: AuthErrorCode) {
    /* Send auth error */
    const msg: AuthErrorMessage = {
      type: ConnectionMessageType.AuthError,
      error,
    };
    this.send.emit(msg);
    /* Set state and close */
    this.disconnect.emit(error);
  }

  private async handleHandshake(msg: HandshakeInitMessage, abortSignal: AbortSignal) {
    Logger.info([
      'Processing connection handshake for proposed connection:',
      msg.sessionIdentifier,
    ]);
    /* Check for version compatibility, if no version is provided, then the client is outdated so send a compatible return code */
    if (this.version !== undefined) {
      if (!msg.version) {
        Logger.info(
          'Rejecting connection of incompatible client version - sending authentication failure for backward compatibility'
        );
        this.sendHandshakeError(
          HandshakeResponseCode.AuthenticationError,
          AuthErrorCode.TokenExpired
        );
        return;
      }
      /* Check for version compatibility comparing the client and server versions */
      if (msg.version !== this.version) {
        Logger.info([
          'Rejecting connection of incompatible client version - version provided:',
          msg.version,
        ]);
        this.sendHandshakeError(HandshakeResponseCode.CompatibilityError);
        return;
      }
    }
    /* Check if authentication is configured */
    let userInfo: UserInfo | undefined;
    let sessionLimit: ClockTimestamp | undefined;
    if (this.authServices) {
      /* Check if the request is permitted */
      const tokenResult = msg.authToken && (await this.authServices.tokenValid(msg.authToken));
      const refused = tokenResult !== TokenValidResult.Valid;
      if (abortSignal.aborted) return; // Back off if the connection is already closed
      if (refused || msg.authToken === undefined) {
        Logger.info([
          'Not accepting connection because of',
          tokenResult,
          '- connection:',
          msg.sessionIdentifier,
        ]);
        if (tokenResult === TokenValidResult.MissingToken)
          this.sendHandshakeError(HandshakeResponseCode.UnknownError);
        else
          this.sendHandshakeError(
            HandshakeResponseCode.AuthenticationError,
            tokenResult === TokenValidResult.Expired
              ? AuthErrorCode.TokenExpired
              : AuthErrorCode.InvalidCredentials
          );
        return;
      }
      /* Get user info */
      userInfo = await this.authServices.getUserInfo(msg.authToken);

      if (userInfo && msg.subUserId) userInfo.subUserId = msg.subUserId;
      if (abortSignal.aborted) return; // Back off if the connection is already closed
      Logger.debug(['User information provided in connection handshake:', userInfo]);
      /* Check whether to check for custom session lifetime */
      if (userInfo && msg.sessionStart) {
        /* Determine the maximum session lifetime, if any */
        const maxDuration = await this.sessionHandler?.maxDuration(userInfo);
        if (abortSignal.aborted) return; // Back off if the connection is already closed
        if (maxDuration !== undefined) {
          sessionLimit = msg.sessionStart + maxDuration;
          if (sessionLimit < this.timer.now) {
            Logger.info([
              'Not accepting connection because the maximum session lifetime is exceeded - connection:',
              msg.sessionIdentifier,
              ' - session start:',
              Logger.timestamp(msg.sessionStart),
              ' - current timestamp:',
              this.timer.now,
            ]);
            this.sendHandshakeError(
              HandshakeResponseCode.AuthenticationError,
              AuthErrorCode.SessionExpired
            );
            return;
          }
        }
      }
    }

    // Get session identifier based on proposed session identifier and/or user identifier
    let connInfo: { connId: string; confirm: boolean };
    try {
      connInfo = await this.sessionHandler?.create(
        this.channel,
        msg.sessionIdentifier,
        userInfo,
        msg.authToken,
        msg.version
      );
    } catch (e) {
      Logger.warn([
        'Closing connection because of handshake error - connection:',
        msg.sessionIdentifier,
        ' - exception:',
        Logger.exception(e),
      ]);
      this.sendHandshakeError();
      return;
    }

    Logger.trace(['Accepting connection:', msg.sessionIdentifier]);
    /* Send affirmative response */
    if (connInfo.confirm) {
      const response: HandshakeResponseMessage = {
        type: ConnectionMessageType.HandshakeResponse,
        response: 'OK',
        sessionIdentifier: connInfo.connId,
      };
      this.send.emit(response);
    }
    /* Set state */
    this.connected.emit();

    /* Activate token handler to be notified about token expiration */
    if (this.authServices) {
      this.tokenHandler = this.authServices.getTokenHandler(() => {
        // Executed in case of error
        Logger.info([
          'Closing connection because of expired authentication - connection:',
          msg.sessionIdentifier,
        ]);
        this.sendAuthError(AuthErrorCode.TokenExpired);
      });
      if (msg.authToken) this.tokenHandler.updateToken(msg.authToken);
    }
    /* If a maximum session lifetime is defined, set a timeout to inform client about authentication failure */
    if (sessionLimit)
      this.sessionTimeout.set(
        this.timer.schedule(() => {
          Logger.info([
            'Closing connection maximum session lifetime is exceeded - connection:',
            msg.sessionIdentifier,
          ]);
          this.sendAuthError(AuthErrorCode.SessionExpired);
        }, sessionLimit - this.timer.now)
      );
  }

  private handleAuthRequest(msg: AuthRequestMessage) {
    if (this.authServices) this.tokenHandler?.updateToken(msg.authToken);
  }

  private handlePingRequest(msg: PingMessage) {
    this.send.emit({ ...msg, type: ConnectionMessageType.PingResponse });
  }

  private readonly channel: BiChannel;
  private readonly sessionHandler: SessionHandler;
  private readonly version?: string;
  private readonly authServices?: IAuthServices;
  private readonly timer: TimerInterface;
  private readonly abortController: AbortController;
  private tokenHandler?: TokenHandler;
  private readonly sessionTimeout = new TimerHolder();

  /** Signals to connect */
  readonly send = new Emitter<[Message]>();
  readonly connected = new Emitter();
  readonly disconnect = new Emitter<[SessionControlError]>();

  /** Messages consumed by this */
  static readonly Messages = [
    ConnectionMessageType.HandshakeInit,
    ConnectionMessageType.AuthRequest,
    ConnectionMessageType.PingRequest,
  ];
}
