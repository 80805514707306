import { LanguageTextResourceMap } from '@sqior/js/language';

export const TelemetryClientTextResources: LanguageTextResourceMap = {
  de: {
    all_customers: 'Alle Kunden',
    all_groups: 'Alle Gruppen',
    anesthesia_nurse: 'Anästhesiepflege',
    anesthesist: 'Anästhesie',
    chats: 'Chat-Nachrichten',
    eighteen_months: 'Achtzehn Monate',
    ent: 'HNO',
    escalations: 'Warnmeldungen',
    gynaecology: 'Gynäkologie',
    half_year: 'Halbjahr',
    holding_nurse: 'Holdingpflege',
    messages: 'Nachrichten',
    messages_by_day: 'Nachrichten pro Tag und Typ',
    month: 'Monat',
    neuromonitoring: 'Neuromonitoring',
    neurosurgery: 'Neurochirurgie',
    nine_months: 'Neun Monate',
    notifications: 'Benachrichtigungen',
    no_data: 'Keine Daten verfügbar',
    operated_ors: 'Betriebene Operationssäle pro Tag und Installation',
    or_coordinator: 'OP-Koordination',
    or_disponent: 'OP-Disponent',
    or_nurse: 'OP-Pflege',
    ors: 'Operationssäle',
    orthopedics: 'Orthopädie',
    procedure_completed: 'Abgeschlossen',
    procedure_perfect: 'Vorbildlich',
    procedure_pending: 'Laufend',
    procedures: 'Eingriffe',
    procedures_by_day: 'Eingriffe pro Tag und Installation',
    quarter: 'Quartal',
    sds_nurse: 'SDS-Pflege',
    surgeon: 'Operateur-Team',
    task_compliance: 'Antwortrate',
    task_compliance_by_day: 'Antwortrate je Nutzergruppe',
    tasks: 'Aufgaben',
    two_years: 'Zwei Jahre',
    urology: 'Urologie',
    users: 'Nutzer',
    users_by_day: 'Verschiedene Nutzer pro Tag',
    ward_nurse: 'Stationspflege',
    year: 'Jahr',
  },
};
