import {
  addMilliseconds,
  addMinutes,
  addSeconds,
  ClockTimestamp,
  now,
  StdTimer,
  TimerHolder,
  TimerInterface,
} from '@sqior/js/data';
import { Emitter, StopListening } from '@sqior/js/event';
import { BiChannel } from './bi-channel';
import { MessageType } from './channel';
import { Message } from './message';
import { Logger } from '@sqior/js/log';

export enum ReliableMessageType {
  Message = 'ReliableMessage',
  Init = 'ReliableInit',
  Confirm = 'ReliableConfirm',
}
type ReliableMessage = Message & {
  sequence: number;
  msg: Message;
  timestamp: ClockTimestamp;
  confirm: number;
  confirmTimestamp?: ClockTimestamp;
};
export type ReliableConfirmation = Message & { sequence: number; timestamp?: ClockTimestamp };
type ReliableUnconfirmed = { sequence: number; msg: Message; timestamp?: ClockTimestamp };

export class ReliableChannel extends BiChannel {
  constructor(
    baseChannel: BiChannel,
    closeTimeout = addMinutes(2),
    outageTimeout = addSeconds(5),
    timer: TimerInterface = new StdTimer()
  ) {
    super();
    this.closeTimeout = closeTimeout;
    this.outageTimeout = outageTimeout;
    this.baseChannel = baseChannel;
    this.timer = timer;
    this.connectBaseChannel();
    this.out.on(MessageType.All, (msg) => {
      this.send(msg);
    });
    /* Open the channel to our users, so that they can already emit messages, these will only be forwarded once the connection is initialized */
    this.in.open();
    this.out.open();
  }

  /** Closes the channel by clearing the timers */
  override close() {
    /* Close all listeners */
    for (const list of this.listeners) list();
    this.listeners = [];
    this.reset();
    this.closeTimer.reset();
    this.outageTimer.reset();
    this.confirmTimer.reset();
  }

  /** Makes sure that the channel is open towards its users */
  override open() {
    if (this.out.isOpen) return;
    /* Reset in case that it is not connected, this may not be done when being connected */
    const connected = !this.baseChannel.allClosed;
    if (!connected) this.reset();
    /* Open the channel to our users */
    this.in.open();
    this.out.open();
    Logger.debug('Opening the reliable channel'); // Log is done after opening, so that message is queued
    /* (Re-)start close timer, if the base channel is not open */
    if (!this.baseChannel.allOpen) {
      this.closeTimer.reset();
      this.startCloseTimer();
    }
  }

  /** Resets the channel to not have any queue messages and to start from scratch */
  private reset() {
    /* Close our channels */
    this.out.close();
    this.in.close();
    /* Reset messages */
    this.unconfirmed = [];
    this.sequence = 0;
    this.sent = -1;
    this.confirm = -1;
  }

  private startCloseTimer() {
    if (this.closeTimer.isSet) return;
    /* Reset initialization state */
    this.initialized = false;
    /* Stop pot. running timers */
    this.outageTimer.reset();
    this.confirmTimer.reset();
    /* Start the timer that closes our channels after some time */
    this.closeTimer.set(
      this.timer.schedule(() => {
        Logger.debug(
          'Resetting the reliable channel after connection was not re-established in time'
        );
        this.reset();
      }, this.closeTimeout)
    );
  }

  private checkInit() {
    /* Reset close timer if applicable */
    this.closeTimer.reset();
    this.baseChannel.out.send({ type: ReliableMessageType.Init, sequence: this.confirm });
  }

  private connectBaseChannel() {
    /* Close all previous state listeners */
    for (const list of this.listeners) list();
    this.listeners = [];
    /* List to all relevant message types */
    this.listeners.push(
      this.baseChannel.in.on(ReliableMessageType.Message, (msg) => {
        this.receive(msg as ReliableMessage);
      })
    );
    this.listeners.push(
      this.baseChannel.in.on(ReliableMessageType.Init, (msg) => {
        this.initial(msg as ReliableConfirmation);
      })
    );
    this.listeners.push(
      this.baseChannel.in.on(ReliableMessageType.Confirm, (msg) => {
        const confMsg = msg as ReliableConfirmation;
        this.confirmed(confMsg.sequence, confMsg.timestamp);
      })
    );
    /* Send out an initial confirmation as soon as both channels are open */
    this.listeners.push(
      this.baseChannel.onClose(() => {
        this.startCloseTimer();
      })
    );
    this.listeners.push(
      this.baseChannel.onOpen(() => {
        this.checkInit();
      })
    );
  }

  setBaseChannel(baseChannel: BiChannel) {
    /* Start the close timer if not already running */
    this.startCloseTimer();
    /* Set new base channel */
    this.baseChannel = baseChannel;
    this.connectBaseChannel();
  }

  private emitMessage(unconfirmed: ReliableUnconfirmed) {
    unconfirmed.timestamp = now();
    const relMsg: ReliableMessage = {
      type: ReliableMessageType.Message,
      sequence: unconfirmed.sequence,
      msg: unconfirmed.msg,
      timestamp: unconfirmed.timestamp,
      confirm: this.confirm,
    };
    const confirmTimestamp = this.getConfirmTimestamp();
    if (confirmTimestamp) relMsg.confirmTimestamp = confirmTimestamp;
    this.baseChannel.out.send<ReliableMessage>(relMsg);
    /* Record the sequence number emitted */
    if (unconfirmed.sequence !== this.sent + 1)
      console.error(
        'Emitting message in reliable channel in inconsistent order - previous:',
        this.sent,
        ' - emitting:',
        unconfirmed.sequence
      ); // using console log as logging would pot. emit further messages causing a loop of infinity
    this.sent = unconfirmed.sequence;
    /* Potentially start a timer to detect outages */
    if (!this.outageTimer.isSet && this.unconfirmed.length) {
      const seq = unconfirmed.sequence;
      this.outageTimer.set(
        this.timer.schedule(() => {
          Logger.debug(['Outage detected after sending message:', seq]);
          this.outage.emit();
          this.outageTimer.reset();
        }, this.outageTimeout)
      );
    }
  }

  private send(msg: Message) {
    /* Stop a potential running confirm timer as we are piggybacking the confirmation */
    this.confirmTimer.reset();
    /* Remember in all cases because it may need to be replayed */
    const unconfirmed = { sequence: this.sequence++, msg: msg };
    this.unconfirmed.push(unconfirmed);
    /* Emit directly if channel is open */
    if (this.initialized) this.emitMessage(unconfirmed);
  }

  private cleanConfirmed(sequence: number) {
    /* Check if there are messages to clean */
    if (this.unconfirmed.length === 0 || this.unconfirmed[0].sequence > sequence) return;
    /* Clear a pot. running outage timer because at least one message has been confirmed */
    this.outageTimer.reset();
    /* Evict all confirmed messages from the queue */
    while (this.unconfirmed.length > 0 && this.unconfirmed[0].sequence <= sequence)
      this.unconfirmed.shift();
  }

  private initial(message: ReliableConfirmation) {
    /* Clean confirmed messages, do this only if we did actually send those messages before */
    if (message.sequence <= this.sent) {
      this.cleanConfirmed(message.sequence);
      this.sent =
        message.sequence; /* Pot. lowering sent counter as some messages might need to be replayed */
    }
    /* Check if a reset is necessary because the partner did reset,
       this is necessary if either a message was received (meaning that some queued unconfirmed messages might be based on this information)
       or if not all messages can be replayed because they have been confirmed already */
    let wasReset = false;
    if (
      message.sequence < 0 &&
      (this.confirm >= 0 ||
        (!this.unconfirmed.length && this.sequence > 0) ||
        (this.unconfirmed.length && this.unconfirmed[0].sequence > 0))
    ) {
      wasReset = true;
      this.reset();
    }
    /* Set as initialized */
    this.initialized = true;
    /* Resend the unconfirmed messages */
    const bytes = 0;
    for (const uc of this.unconfirmed) {
      //bytes += JSON.stringify(splitMessage(uc.msg)[0]).length;
      this.emitMessage(uc);
    }
    /* Open the channels to our users, if applicable */
    if (this.baseChannel.allOpen && !this.out.isOpen) {
      this.in.open();
      this.out.open();
    }
    /* Log stats about the size of unconfirmed messages */
    if (wasReset) Logger.debug('Resetting reliable channel because partner did reset');
    if (bytes)
      Logger.debug([
        'Unconfirmed message backlog length:',
        this.unconfirmed.length,
        ' - with total size:',
        bytes,
      ]);
  }

  private getConfirmTimestamp(): ClockTimestamp | undefined {
    /* Correct the timestamp by the time that the message was waiting internally */
    return this.confirmSendTimestamp && this.confirmReceiveTimestamp
      ? this.confirmSendTimestamp + now() - this.confirmReceiveTimestamp
      : undefined;
  }

  private receive(msg: ReliableMessage) {
    /* Handle embedded confirmation */
    this.confirmed(msg.confirm, msg.confirmTimestamp);
    /* Set confirmation of the received message */
    if (msg.sequence !== this.confirm + 1)
      Logger.error([
        'Received message in reliable channel in inconsistent order - previous:',
        this.confirm,
        ' - received:',
        msg.sequence,
      ]);
    this.confirm = msg.sequence;
    this.confirmSendTimestamp = msg.timestamp;
    this.confirmReceiveTimestamp = now();
    /* Start timer for confirmation, if not done already */
    if (!this.confirmTimer.isSet)
      this.confirmTimer.set(
        this.timer.schedule(() => {
          /* Send explicit confirmation message */
          if (this.baseChannel.out.isOpen) {
            const relConf: ReliableConfirmation = {
              type: ReliableMessageType.Confirm,
              sequence: this.confirm,
            };
            const confirmTimestamp = this.getConfirmTimestamp();
            if (confirmTimestamp) relConf.timestamp = confirmTimestamp;
            this.baseChannel.out.send<ReliableConfirmation>(relConf);
          }
          this.confirmTimer.reset();
        }, addMilliseconds(500))
      );
    /* Forward to users */
    this.in.send(msg.msg);
  }

  private confirmed(confirm: number, timestamp?: ClockTimestamp) {
    /* Determine the latency */
    if (timestamp) this.latency.emit((now() - timestamp) / 2);
    /* Clean confirmed messages */
    this.cleanConfirmed(confirm);
    /* Start a new outage timer if there are unconfirmed messages left */
    if (!this.outageTimer.isSet && this.unconfirmed.length > 0) {
      const seq = this.unconfirmed[0].sequence;
      this.outageTimer.set(
        this.timer.schedule(() => {
          Logger.debug(['Outage detected when waiting for confirmation of message:', seq]);
          this.outage.emit();
          this.outageTimer.reset();
        }, this.outageTimeout)
      );
    }
  }

  private readonly closeTimeout: ClockTimestamp;
  private baseChannel: BiChannel;
  private readonly timer: TimerInterface;
  private initialized = false;
  private sequence = 0;
  private sent = -1;
  private confirm = -1;
  private confirmSendTimestamp?: ClockTimestamp;
  private confirmReceiveTimestamp?: ClockTimestamp;
  private unconfirmed: ReliableUnconfirmed[] = [];
  private readonly confirmTimer = new TimerHolder();
  private readonly closeTimer = new TimerHolder();
  private listeners: StopListening[] = [];
  private readonly outageTimer = new TimerHolder();
  private readonly outageTimeout: ClockTimestamp;
  readonly outage = new Emitter();
  readonly latency = new Emitter<[number]>();
}
