import { ReactNode, useContext, useEffect } from 'react';
import { OperationContext } from '@sqior/react/operation';
import { PublicAuthProvider } from '@sqior/react/uiauthkc';
import { AuthContext } from '@sqior/react/uiauth';
import { RefreshPairingToken } from '@sqior/viewmodels/app';
import { AddOperation } from '@sqior/js/operation';
import { KeyExpirationState } from '@sqior/js/authbase';

type PublicAuthProviderProps = {
  token: string;
  children: ReactNode;
};

export function PublicAuthWrapper(props: PublicAuthProviderProps) {
  const dispatcher = useContext(OperationContext);
  const authProvider = useContext(AuthContext).provider as PublicAuthProvider;

  useEffect(() => {
    switch (authProvider.tokenStatus()) {
      case KeyExpirationState.NotFound:
      case KeyExpirationState.Expired: {
        authProvider.logOut(); // TODO: inform user about pairing removal
        break;
      }
      case KeyExpirationState.InGracePeriod: {
        const operation = dispatcher.start(RefreshPairingToken(props.token)) as AddOperation;
        authProvider.refreshToken(operation);
        break;
      }
      default:
        break;
    }
  }, [dispatcher, authProvider]);
  return <>{props.children}</>;
}
