import { Bytes, LogicalTimestamp, Value, ValueObject } from '@sqior/js/data';
import { IdOperation } from './id-operation';
import { OperationType } from './operation';

export type DeleteResult = LogicalTimestamp;

export class DeleteOperation extends IdOperation {
  constructor(id: string, value?: Value) {
    super(OperationType.Delete, id);
    if (value !== undefined) this.requestData = value;
  }

  override toJSON() {
    const obj: ValueObject = { id: this.id };
    if (this.requestData) obj['data'] = this.requestData;
    return obj;
  }

  static fromJSON(json: Value) {
    if (typeof json === 'object' && !(json instanceof Bytes) && !(json instanceof Array)) {
      const id = json['id'];
      if (id !== undefined && typeof id === 'string') return new DeleteOperation(id, json['data']);
    } else if (typeof json === 'string') return new DeleteOperation(json);
    throw new Error('Provided value cannot be deserialized to DeleteOperation');
  }

  requestData?: Value;
}
