import { Emitter } from '@sqior/js/event';

export enum CacheStateType {
  Valid,
  Invalid,
  Dynamic,
  Closable,
}

export class CacheState {
  constructor(type = CacheStateType.Valid) {
    this.validity = type !== CacheStateType.Invalid;
    this.refCount = 1;
    if (type === CacheStateType.Dynamic || type === CacheStateType.Closable)
      this.invalidated = new Emitter();
    if (type === CacheStateType.Closable) this.closed = new Emitter();
  }

  get valid() {
    return this.validity;
  }

  invalidate() {
    if (!this.validity) return;
    if (!this.invalidated) throw new Error('Trying to invalidate a permanently valid cache state');
    const emitter = this.invalidated;
    this.validity = false;
    delete this.invalidated;
    delete this.closed;
    emitter.emit();
  }

  incRef() {
    if (!this.refCount)
      throw new Error('Reference count is incremented, although it is already at zero');
    this.refCount++;
  }

  decRef() {
    if (!this.refCount)
      throw new Error('Reference count is decremented, although it is already at zero');
    this.refCount--;
    if (!this.refCount) this.close();
  }

  protected close() {
    if (!this.closed) return;
    const emitter = this.closed;
    delete this.closed;
    emitter.emit();
  }

  private validity: boolean;
  refCount: number;
  invalidated?: Emitter;
  closed?: Emitter;
}
