import { ClockTimestamp } from '@sqior/js/data';
import { Entity, EntityHeader } from '@sqior/js/entity';
import { OperationSpec, OperationType } from '@sqior/js/operation';

/** Patient transport indicator states */
export enum PatientTransportIndicatorState {
  Ordered = 'transport-ordered',
  Running = 'transport-running',
  Cancelled = 'transport-cancelled',
  Done = 'transport-done',
  Holding = 'holding',
  Transfer = 'transfer',
}

/* Entity representing the input parameter options of a transport */

export const TransportOrderInputType = 'TransportOrderInput';
export type TransportOrderLocationInput = { label: string; select: Entity };
export type TransportOrderPriorityInput = { label: string; priority: number };
export enum TransportOrderInfectiousScale {
  None = 'None',
  YesNo = 'YesNo',
  LARE = 'LARE',
}
export type TransportOrderInfectiousState = boolean | TransportOrderLARE;
export type TransportOrderInput = EntityHeader & {
  from: TransportOrderLocationInput[];
  to: TransportOrderLocationInput[];
  options: boolean;
  infectiousScale: TransportOrderInfectiousScale;
  infectiousDefault?: TransportOrderInfectiousState;
  related?: Entity;
  commentDefault?: string;
  priorities: TransportOrderPriorityInput[];
  priorityDefault?: number;
  timestampDefault?: ClockTimestamp;
};

/* Enum representing the urgency of the transport */

export enum TransportOrderPriority {
  Emergency = 10,
  Immediately = 5, // As soon as possible (default)
  AtDefinedTime = 4,
}

/* Entity representing the selected input parameters of a transport order */

export const TransportOrderParametersType = 'TransportOrderParameters';
export enum TransportOrderLARE {
  A = 'LARE-A',
  B = 'LARE-B',
  C = 'LARE-C',
  D = 'LARE-D',
}
export type TransportOrderParameters = EntityHeader & {
  from: Entity;
  to: Entity;
  priority: number;
  infectious: TransportOrderInfectiousState;
  comment?: string;
  pickUpTimestamp?: ClockTimestamp;
  related?: Entity;
};
export function makeTransportOrderParameters(
  from: Entity,
  to: Entity,
  priority: number,
  infectious: TransportOrderInfectiousState,
  comment?: string,
  pickUpTimestamp?: ClockTimestamp,
  related?: Entity
): TransportOrderParameters {
  const res: TransportOrderParameters = {
    entityType: TransportOrderParametersType,
    from,
    to,
    priority,
    infectious,
  };
  if (comment) res.comment = comment;
  if (pickUpTimestamp) res.pickUpTimestamp = pickUpTimestamp;
  if (related) res.related = related;
  return res;
}

/** Entity representing the parameter options for selecting a transport location */

export const TransportLocationSelectionType = 'TransportLocationSelection';
export type TransportLocationSelection = EntityHeader & {
  title: string;
};

/** Stream operation for searching locations  */

export const TransportLocationSearchPath = 'search-transport-location';
export type TransportLocationSearchResult = TransportOrderLocationInput & { id: string };
export const TransportLocationSearch: OperationSpec<string> = {
  type: OperationType.Stream,
  path: TransportLocationSearchPath,
  data: '',
};
