import { ClockTimestamp, now } from '@sqior/js/data';
import { DoNotCatchException } from '@sqior/js/error';
import { LogContent, LogData, LogLevel } from './log-data';
import { TextLogBackend } from './text-log-backend';

export type LogMessage = {
  level: LogLevel;
  content: LogContent;
  classified?: LogContent;
  timestamp?: ClockTimestamp;
};
export enum ErrorReportingMode {
  None = 'none',
  Log = 'log',
  Throw = 'throw',
  LogAndThrow = 'log-and-throw',
}
export type LogOptions = {
  level: LogLevel;
  logClassified?: boolean;
  validate?: boolean;
  errorReportingMode?: ErrorReportingMode;
};

export abstract class LogInterface {
  constructor(options: LogOptions) {
    this.options = options;
  }

  log(message: LogMessage) {
    if (message.level <= this.options.level) {
      const classified = message.classified;
      if (this.options.logClassified && classified) {
        const logData: LogData = {
          level: message.level,
          content: classified,
        };
        if (message.timestamp) logData.timestamp = message.timestamp;
        this.doLog(logData);
      } else {
        const logData: LogData = {
          level: message.level,
          content: message.content,
        };
        if (message.timestamp) logData.timestamp = message.timestamp;
        this.doLog(logData);
      }
    }
  }

  offer(level: LogLevel, log: (logClassified: boolean) => LogContent) {
    if (level <= this.options.level)
      this.log({ level: level, content: log(this.options.logClassified || false) });
  }

  logOnLevel(level: LogLevel, content: LogContent, classified?: LogContent) {
    const logData: LogMessage = {
      level,
      content,
      timestamp: now(),
    };
    if (classified) logData.classified = classified;
    this.log(logData);
  }

  error(content: LogContent, classified?: LogContent) {
    this.logOnLevel(LogLevel.Critical, content, classified);
  }
  warn(content: LogContent, classified?: LogContent) {
    this.logOnLevel(LogLevel.Warning, content, classified);
  }
  info(content: LogContent, classified?: LogContent) {
    this.logOnLevel(LogLevel.Info, content, classified);
  }
  debug(content: LogContent, classified?: LogContent) {
    this.logOnLevel(LogLevel.Debug, content, classified);
  }
  trace(content: LogContent, classified?: LogContent) {
    this.logOnLevel(LogLevel.Trace, content, classified);
  }

  /** Checks whether validation shall be done */
  get validate() {
    return this.options.validate ?? this.errorReportingMode !== ErrorReportingMode.None;
  }
  /** Sets the validation mode */
  set validate(mode: boolean) {
    this.options.validate = mode;
  }
  /** Returns the error report mode */
  get errorReportingMode() {
    return this.options.errorReportingMode ?? ErrorReportingMode.LogAndThrow;
  }
  /** Sets the error reporting mode */
  set errorReportingMode(mode: ErrorReportingMode) {
    this.options.errorReportingMode = mode;
  }
  /** Combined method that logs and throws depending on the mode specified */
  reportError(content: LogContent, mode?: ErrorReportingMode, classified?: LogContent) {
    mode = mode ?? this.errorReportingMode;
    if (mode === ErrorReportingMode.Log || mode === ErrorReportingMode.LogAndThrow)
      this.error(content, classified);
    if (mode === ErrorReportingMode.Throw || mode === ErrorReportingMode.LogAndThrow)
      throw new Error('ERROR: ' + TextLogBackend.convert(content));
  }

  exception(exc: unknown): string {
    /* Check if this is an exception type that is not supposed to be caught */
    if (exc instanceof DoNotCatchException) throw exc;
    if (exc instanceof Error) return exc.message;
    return JSON.stringify(exc);
  }
  timestamp(ts: ClockTimestamp) {
    return new Date(ts).toISOString();
  }

  protected abstract doLog(data: LogData): void;

  readonly options: LogOptions;
}

export function logLevel(level: string): LogLevel {
  switch (level.toLowerCase()) {
    case 'fatal':
      return LogLevel.Fatal;
    case 'critical':
      return LogLevel.Critical;
    case 'warning':
      return LogLevel.Warning;
    case 'info':
      return LogLevel.Info;
    case 'debug':
      return LogLevel.Debug;
    case 'trace':
      return LogLevel.Trace;
  }
  return LogLevel.Info;
}
